import  axios  from "axios"

export default {
    namespaced: true,
    state: {
        markSheetOverlay: false,
        markSheetLoading: false,
        expandLessons: false,
        totalStudents: null,
        teacherLessons: [],
        lessonSelected: {
            lesson: '',
            className: '',
            subjectCode: '',
            subjectTitle: '',
            formLevel: ''
        },
        termMarkRecords: [],
        displayPresetComment: false,
        updatedTermMarkRecord:{},
        studentSelected: '',
        selectedRecordIndex: -1,
        presetComments: [],
        presetComment: '',
        termConfiguration: {}, 
    },    
    getters: {
        getMarkSheetOverlay(state){
            return state.markSheetOverlay
        },
        getMarkSheetLoading(state){
            return state.markSheetLoading
        },
        getExpandLessons(state){
            return state.expandLessons
        },        
        getTeacherLessons(state){
            return state.teacherLessons
        },
        getLessonSelected(state){
            return state.lessonSelected
        },
        getTermMarkRecords(state){
            return state.termMarkRecords
        },
        getTotalStudents(state){
            return state.totalStudents
        },
        getDisplayPresetComment(state){
            return state.displayPresetComment
        },
        getUpdatedTermMarkRecord(state){
            return state.updatedTermMarkRecord
        },
        getStudentSelected(state){
            return state.studentSelected
        },
        getSelectedRecordIndex(state){
            return state.selectedRecordIndex
        },
        getPresetComments(state){
            return state.presetComments
        },        
        getPresetComment(state){
            return state.presetComment
        },
        getTermConfiguration(state){
            return state.termConfiguration
        },
    },
    mutations: {
        setMarkSheetOverlay(state, value){
            state.markSheetOverlay = value
        },
        setMarkSheetLoading(state, value){
            state.markSheetLoading = value
        },
        setExpandLessons(state, value){
            state.expandLessons = value
        },
        setTeacherLessons(state, value){
            state.teacherLessons = value
        },        
        setLessonSelected(state, value){
            state.lessonSelected = value
        },
        setTermMarkRecords(state, value){
            state.termMarkRecords = value
        },
        setTotalStudents(state, value){
            state.totalStudents = value
        },
        setDisplayPresetComment(state, value){
            state.displayPresetComment = value
        },
        setUpdatedTermMarkRecord(state, value){
            state.updatedTermMarkRecord = value
        },
        setStudentSelected(state, value){
            state.studentSelected = value
        },
        setSelectedRecordIndex(state, value){
            state.selectedRecordIndex = value
        },
        setPresetComments(state, value){
            state.presetComments = value
        },       
        setPresetComment(state, value){
            state.presetComment = value
        },
        setTermConfiguration(state, value){
            state.termConfiguration = value
        },
        
    },
    actions: {
        getLessons(context, id){
            let url = '/api/teacher-lessons/' + id;
            return axios.get(url);
        },

        getStudents({getters}, term){
            let url = '/api/teacher-lesson-students/'
            + getters.getLessonSelected.className + '/'           
            + term + '/'
            + getters.getLessonSelected.subjectCode;
            return axios.get(url);
        },

        getPresetCommentsList(){
            let url = '/api/preset-comments';
            return axios.get(url);
        },

        save({getters}){
            let url = '/api/term-marks';
            console.log(getters.getUpdatedTermMarkRecord);
            return axios.post(url, getters.getUpdatedTermMarkRecord);
        },

        getTermConfiguration ({ getters }) {
            let url = '/api/term-configuration/' 
            + getters.getLessonSelected.formLevel;
            // console.log(url);
            return axios.get(url);
        }
    }
}