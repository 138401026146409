<template>
    <v-row>           
    <v-col>
        <v-card
            class="pa-4 mx-auto"
            width="700"
            height="75vh"
        >
            <v-card-title>
                Reset Employee Password
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details=""
                    clearable
                ></v-text-field>
            </v-card-title>
            <v-data-table
                class="mt-10"
                :headers="headers"
                :items="employees"
                :search="search"
                fixed-header
                height="45vh"
                :loading="loading"
            >
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                        medium
                        class="mr-2"
                        @click="resetPassword(item)"
                    >
                        mdi-lock-reset
                    </v-icon>
                </template>
            </v-data-table>
            <v-dialog
                v-model="dialog"
                persistent
                max-width="360"
            >
                <v-card
                    class="pa-4"
                >
                    <v-card-title>
                        {{ cardTitle }}
                    </v-card-title>
                    <v-card-text
                        class='text-subtitle-1 font-weight-bold'
                    >
                        {{ cardText }}
                    </v-card-text>
                    <v-progress-linear
                        indeterminate
                        v-if="progress"
                    ></v-progress-linear>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="close"
                            v-if="cancel"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            color="primary"
                            text
                            @click="resetPasswordConfirm"
                            outlined
                            v-if="ok"
                        >
                            OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </v-col>
    </v-row>        
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    created () {
        this.initialize();
    },
    data: () => ({
        search: '',
        employees: [],
        headers: [
            {text: 'ID', align: 'start', sortable: 'false', value: 'id', width: '50'},
            {text: 'Last Name', value: 'lastName', width: '100'},
            {text: 'First Name', value: 'firstName', width: '100'},
            {text: 'User Name', value: 'userName', width: '100'},
            {text: 'Reset', value: 'actions', sortable: 'false', width: '50'}
        ],
        loading: true,
        cardText: '',
        cardTitle: '',
        employeeId: null,
        dialog: false,
        progress: false,
        passwordReset: false,
        cancel: true,
        ok: true,
    }),
    methods: {        
        ...mapActions({
            getEmployees: 'employees/getEmployees',
            resetPasswordPost: 'auth/resetPassword',
        }),
        ...mapMutations({
            setResetEmployeeId: 'auth/setResetEmployeeId',
        }),
        async initialize(){
            try {
                const employeePromise = await this.getEmployees();                
                let records = employeePromise.data;
                records.forEach(record => {
                    let employee = {
                        id: record.id,
                        lastName: record.last_name,
                        firstName: record.first_name,
                        userName: record.user_name
                    }
                    this.employees.push(employee); 
                })
                this.loading = false;
            } catch (error) {
                console.log(error);
            }
        },
        resetPassword(item){
            console.log(item);
            this.employeeId = item.id;
            console.log(this.employeeId);
            this.dialog = true;
            this.cardTitle = 'Are you sure you want to reset';
            this.cardText = item.firstName + ' ' + item.lastName;
        },
        resetPasswordConfirm(){
            if(this.passwordReset){
                this.dialog = false;
                this.passwordReset = false;
                this.cancel = true;
            }
            else{
                console.log('reset confirmed');
                this.cardTitle = 'Please wait..';
                this.cardText = '';
                this.ok = false,
                this.cancel = false,
                this.progress = true;
                this.postReset();
            }            
        },
        async postReset(){            
            this.setResetEmployeeId(this.employeeId);
            try {
                let response = await this.resetPasswordPost();
                console.log(response);
                this.cardTitle = "Password reset successfully."
                this.cardText = "New Password: " + response.data.remember_token
                this.passwordReset = true;                
            } catch (error) {
                console.log(error);
                this.passwordReset = true; 
                this.cardTitle = "An error has occured " + error.status
            }
            this.progress = false;
            this.ok = true;
            
        },        
        close(){
            this.dialog = false;
        },
        
    }
}
</script>