<template>
     <v-row>           
        <v-col>
            <v-card
                class="pa-0 mx-auto"
                width="950"
            >
                <v-card-title class="pb-0">
                    Edit / View Students                   
                    
                    <v-spacer></v-spacer>

                    <students-attendance
                        v-if="displayAttendance"
                    ></students-attendance>

                    <v-dialog
                        v-model="dialog"
                        max-width="800px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                outlined
                                text
                                v-bind="attrs"
                                v-on="on"
                            >
                                Add New Student
                            </v-btn>
                        </template>

                        <students-edit
                            v-if="dialog"                            
                            v-bind:formTitle="formTitle"
                            v-bind:formClasses="formClasses"
                            v-on:close="closeEdit"
                            v-on:update-students="initialize" 
                        ></students-edit>  

                    </v-dialog>
                    
                </v-card-title>

                <div class="pl-2">
                    <v-btn 
                        :color="current.color" 
                        :depressed="current.depressed" 
                        class="ma-2" 
                        small
                        tile
                        @click="filterCurrent()"
                    >
                    <v-icon v-if="currentStudents" left light>mdi-check-circle</v-icon>
                        Current
                    </v-btn>
                        <v-btn 
                        :color="archive.color" 
                        :depressed="archive.depressed" 
                        class="ma-2" 
                        small
                        tile
                        @click="filterArchive()"
                    >
                        <v-icon v-if="!currentStudents" left light>mdi-check-circle</v-icon>
                        Archive
                    </v-btn>
                </div>

                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="students"
                        fixed-header                        
                        height="45vh"
                        :calculate-widths="true" 
                        :search="search"
                        :loading="loading"
                        :custom-filter="customFilter"
                    >
                        <template v-slot:top>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                                class="mb-8"
                                clearable
                            ></v-text-field>

                            <v-dialog
                                v-model="dialogDelete"
                                max-width="430px"
                                persistent
                            >
                                <v-card
                                    v-if="confirmDelete"
                                >
                                    <v-card-title>{{ deleteStudentSelected }}</v-card-title>
                                    <v-card-text>                                        
                                        <v-select
                                            :items="statuses"
                                            v-model="status"
                                            label="Status"                                            
                                            item-text="detail"
                                            item-value="id"
                                            autofocus
                                            hide-details
                                            ref="student-status"
                                            clearable
                                            open-on-clear
                                        ></v-select>
                                    </v-card-text>
                                    <v-card-actions
                                        class="px-6"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="closeDelete"
                                            outlined
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="deleteStudentConfirm"
                                            outlined
                                        >
                                            Delete
                                        </v-btn>                                        
                                    </v-card-actions>
                                </v-card>
                                <v-expand-transition>
                                    <v-card
                                        v-show="deleting"
                                        color="primary"
                                        dark
                                        class="pa-4"
                                    >
                                        <v-card-text>
                                        {{ studentDeleted }}
                                        <v-progress-linear
                                                indeterminate
                                                color="white"
                                                class="mb-0 mt-4"
                                        ></v-progress-linear>
                                        </v-card-text>
                                    </v-card>
                                </v-expand-transition>
                            </v-dialog>
                        </template>

                        <template  v-slot:[`item.actions`]="{ item }">
                            <v-btn
                                tile
                                color="primary"
                                @click="editStudent(item)"
                                class="mr-6"
                                small
                            >
                                Edit
                                <v-icon
                                    small
                                >
                                    mdi-pencil
                                </v-icon>
                                
                            </v-btn>
                            <v-btn
                                text
                                outlined
                                @click="deleteStudent(item)"
                                small
                            >    
                                Delete
                                <v-icon
                                    small                                    
                                >
                                    mdi-delete
                                </v-icon>
                                
                            </v-btn>    
                        </template>

                        <template v-slot:item="props">
                            <tr>
                                <td>
                                    <v-img
                                        :src="props.item.picture"
                                        max-width="50"
                                        class="my-4"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="primary"
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </td>
                                <td>{{ props.item.student_id }}</td>
                                <td>{{ props.item.name }}</td>
                                <td>{{ props.item.form_class_id }}</td>
                                <td>{{ props.item.gender }}</td>
                                <td>{{ props.item.age }}</td>
                                <td>
                                    <v-btn
                                        tile
                                        color="primary"
                                        @click="editStudent(props.item)"
                                        class="mr-6"
                                        x-small
                                    >
                                        Edit
                                        <v-icon
                                            x-small
                                        >
                                            mdi-pencil
                                        </v-icon>
                                        
                                    </v-btn>
                                    <v-btn
                                        text
                                        outlined
                                        @click="deleteStudent(props.item)"
                                        x-small
                                        v-if="currentStudents"
                                    >    
                                        Delete
                                        <v-icon
                                            x-small                                    
                                        >
                                            mdi-delete
                                        </v-icon>
                                        
                                    </v-btn>    
                                </td>
                            </tr>
                        </template>

                    </v-data-table>

                    <v-snackbar
                        v-model="snack.display"
                        :timeout="3000"
                        :color="snack.color"
                    >
                        {{ snack.text }}
                        <template v-slot:action="{ attrs }">
                            <v-btn
                                v-bind="attrs"
                                text
                                @click="snack.display = false"
                            >
                                Close
                            </v-btn>
                        </template>
                    </v-snackbar>

                    <v-overlay
                        absolute
                        :value="overlay"
                    >
                        <v-progress-circular
                            indeterminate
                            size="64"
                            color="primary"
                        ></v-progress-circular>
                    </v-overlay> 

                </v-card-text>                
            </v-card>            
        </v-col>
     </v-row>            
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import StudentsEdit from './StudentsEdit';
import StudentsAttendance from './StudentsAttendance.vue';

export default {
    components: {         
        StudentsEdit,
        StudentsAttendance, 
    },
    created () {
        this.initialize();
    },
    data: () => ({
       headers: [
            {text: 'Picture', align: 'start', sortable: false, value: 'picture', width: '100'},
            {text: 'ID', align: 'start', sortable: false, value: 'student_id', width: '100'},
            {text: 'Name', align: 'start', sortable: false, value: 'name', width: '200'},
            {text: 'Class', align: 'start', value: 'form_class_id', width: '75'},
            {text: 'Gender', align: 'start', value: 'gender', width: '60'},
        //  {text: 'DOB', align: 'start', value: 'date_of_birth', width: '120'},
            {text: 'Age', align: 'start', value: 'age', width: '120'},
            {text: '', align: 'start', sortable: false, value: 'actions', },
        ],
        students: [],
        studentsArchive: [],
        studentsCurrent: [],
        search: '',
        loading: true,
        snack: {
            display:false,
            text: null,
            color: 'primary',
        },
        options: {
            itemsPerPage: -1,
        },
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {},
        deletedItem: {},
        defaultItem: {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            birth_certificate_pin: '',
            gender: '',
            form_class_id: ''
        },
        gender: ['M', 'F'],
        formClasses: [],
        menu: false,
        date: null,
        expanded: [],
        overlay: false,
        rules: [
            value => !!value || 'Required'
        ],
        formHasErrors: false,
        errorMessage: '',
        statuses: [],
        status: 3,
        deleteStudentSelected: null,
        confirmDelete: true,
        deleting: false,
        displayAttendance: true,
        current: {
            color: 'primary',
            depressed: false,
        },
        archive: {
            color: 'gray',
            depressed: true
        },
        currentStudents: true,
    }),

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Student' : 'Edit Student'
        },
        studentDeleted () {
            return 'Deleting ' + this.deletedItem.first_name + ' ' + this.deletedItem.last_name;
        },
    },

    watch: {
        dialog (val) {
            val || this.close()
        },
        dialogDelete (val) {
            val || this.closeDelete()
        },      
    },

    methods: {
        ...mapActions({
            getStudents: 'students/getStudents',
            getFormClasses: 'termReports/getFormClassesList',
            postStudent: 'editViewStudents/postStudent',
            getStudentStatus: 'editViewStudents/getStudentStatus',
            delete: 'editViewStudents/deleteStudent',
        }),
        ...mapMutations({
            setStudent: 'editViewStudents/setStudent',
            setEditedStudent: 'students/setEditedStudent',
        }),
        async initialize(){
            this.overlay = true;
            try {
                const studentsPromise = this.getStudents();
                const formClassesPromise = this.getFormClasses();
                const studentStatusPromise = this.getStudentStatus();

                const [
                    { data: dataStudents },
                    { data: dataFormClasses },
                    { data: dataStudentStatus }
                ] = await Promise.all([
                    studentsPromise,
                    formClassesPromise,
                    studentStatusPromise
                ])

                const { current_students, archived_students } = dataStudents;

                this.studentsCurrent = current_students.map(value => {
                    value.name = value.last_name + ', ' + value.first_name;
                    if(!value.picture){
                        value.picture = require("../assets/icons/student2.svg")
                    }
                    return value;
                });

                this.students = this.studentsCurrent;

                this.studentsArchive = archived_students.map(value => {
                    value.name = value.last_name + ', ' + value.first_name;
                    if(!value.picture){
                        value.picture = require("../assets/icons/student2.svg")
                    }
                    return value;
                })

                this.formClasses = dataFormClasses;

                dataStudentStatus.forEach(record => {
                    if(record.id > 2){
                        this.statuses.push(record);
                    }
                })
                
                this.loading = false;
            } catch (error) {
                console.log(error);
                console.log(error.response);
            }
            this.overlay = false;
        },
        editStudent(item){
            //console.log(`Edit ${ item }`);
            // console.log(item);
            this.setEditedStudent(item);
            this.editedIndex = this.students.indexOf(item);
            this.editedItem = Object.assign({}, item);
            // console.log(item);
            this.date = item.date_of_birth;
            this.dialog = true;
        },
        close () {
            this.dialog = false;
            this.error = false;
            this.saved = false;
            this.date = null;
            this.$nextTick( () => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            Object.keys(this.editedItem).forEach(f => {
                if(f === 'first_name' || f === 'last_name' || f === 'form_class_id'){
                    // this.$refs[f].reset()
                }
                
            })
        },
        async save () {
            console.log('saving...')
            this.overlay = true
            console.log(this.editedItem);
            this.setStudent(this.editedItem);
            this.formHasErrors = false;
            //console.log(Object.keys(this.editedItem));
            Object.keys(this.editedItem).forEach( f => {
                if(f === 'first_name' && !this.editedItem[f]){
                    this.formHasErrors = true;
                    this.$refs[f].validate(true);
                } 
                if(f === 'last_name' && !this.editedItem[f]){
                    this.formHasErrors = true;
                    this.$refs[f].validate(true);
                } 
                if(f === 'form_class_id' && !this.editedItem[f]){
                    this.formHasErrors = true;
                    this.$refs[f].validate(true);
                } 
               
            })
            if(!this.formHasErrors){
                console.log('fields present');
                try {
                    const { data } = await this.postStudent();
                    console.log(data)
                    let studentsPromise = await this.getStudents();
                    this.students = studentsPromise.data;
                    //console.log(response);
                    this.error = false;
                    this.saved = true;  
                } catch (error) {
                    console.log(error.response);
                    this.errorMessage = 'Error Occured';
                    this.saved = false; 
                    this.error = true;                    
                }
            }
            else{
                //console.log('fields empty');
                this.saved = false; 
                this.error = true;
                this.errorMessage = 'Required Fields Empty'; 
            }
            
            this.overlay = false;
                     
        },
        deleteStudent(item){
            //console.log(item);           
            this.deletedItem = Object.assign({}, item);
            this.deleteStudentSelected = item.first_name + ' ' + item.last_name;
            this.dialogDelete = true;
            this.confirmDelete = true;
            //console.log(this.$refs);
            this.$nextTick(() => {
                // console.log(this.$refs['student-status'])
                //this.$refs['student-status'].isActive = true;
            })
           
        },
        addStudent(){
            //
        },
        saveDate(date){
            this.$refs.menu.save(date);
        },
        closeDelete(){
            this.dialogDelete = false;
            this.status = 3;            
        },
        async deleteStudentConfirm(){
            console.log('deleting...');
            this.confirmDelete = false;
            setTimeout(() => {
                this.deleting = true;
            });
            
            //console.log(this.deletedItem);
            this.setStudent({
                student_id: this.deletedItem.student_id,
                student_status_id: this.status
            });
            try {
                const response = await this.delete();
                console.log(response)
                this.initialize();            
                this.deleting = false;  
                setTimeout(() => {
                    this.snack.display = true;
                    this.snack.text = this.deletedItem.first_name + ' ' + this.deletedItem.last_name + ' deleted.'
                    this.snack.color = 'primary'
                })                              
            } catch (error) {
                if(error.response) console.log(error.response);
                console.log(error);
                setTimeout(() => {
                    this.snack.color = 'red';
                    this.snack.text = `Error occured. Unable to delete ${this.deletedItem.first_name + ' ' + this.deletedItem.last_name }.`
                    this.snack.display = true;
                }) 
            }

            setTimeout(() =>{
                this.dialogDelete = false;                 
            })
                     
            
        },

        closeEdit () {
            this.dialog = false;            
            this.$nextTick( () => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.setEditedStudent(this.editedItem);
                this.editedIndex = -1;
            });            
        },

        customFilter (value, search) {
            return value != null &&
            search != null &&
            value.toString().toLocaleUpperCase().indexOf(search.toString().toLocaleUpperCase()) !== -1 &&
            value.toString().indexOf('image') == -1
        },

        filterArchive () {
            this.archive.color = 'primary';
            this.archive.depressed = false;
            this.current.color = 'gray';
            this.current.depressed = true;
            this.students = [];
            this.currentStudents = false;
            this.$nextTick(() => {
                this.students = this.studentsArchive
            })
        },

        filterCurrent () {
            this.archive.color = 'gray';
            this.archive.depressed = true;
            this.current.color = 'primary';
            this.current.depressed = false;
            this.students = [];
            this.currentStudents = true;
            this.$nextTick(() => {
                this.students = this.studentsCurrent
            })
        },
    }
}
</script>