<template>
    <v-col class="pr-0 pb-0" cols="4">
        <v-row class="mx-3">
            <v-card
                flat
                width="400"
                align="center"
                justify="center"
                color="grey lighten-5"
                dark
            >
                <span 
                    class="mr-3 text-subtitle-1 primary--text"                                         
                > {{ studentName }}</span>                                 
                <span 
                    class="text-subtitle-1 primary--text"
                >{{ classId }}</span>
            </v-card>
        </v-row>

        <v-row class="mx-3">
            <v-card                                    
                width="400"
                flat
                color="grey lighten-5"                
                dark                                    
            >
                <v-row
                    class="pa-3"
                >
                    <v-col
                        align="center"
                        justify="center"
                        v-for="item in academicPeriod"
                        :key="item.title"
                        class="pa-0"
                    >
                        <div 
                            class="text-caption primary--text"
                        >{{item.title}}</div>
                        <div 
                            class="text-caption primary--text"
                        >{{item.value}}</div>
                    </v-col>
                </v-row>
            </v-card>                                 
        </v-row>

        <v-row class="mx-3">
            <v-card
                height="180"
                width="300"
                flat
            >
                <div>
                    <v-list dense>
                        <v-list-item
                            v-for="item in recordSummary"
                            :key="item.title"
                        >
                            <v-list-item-content>
                                <v-list-item-title 
                                    class="text-caption"
                                >
                                    {{ item.title }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-card
                                width="100"                                                                                                       
                                flat
                            >
                                <v-text-field
                                    v-if="!item.select"
                                    :disabled = "item.disabled"
                                    :filled = "item.disabled" 
                                    @blur="updateRecord"
                                    dense
                                    hide-details
                                    v-model="studentRecord[item.model]"
                                    height="25"
                                    class="text-caption pa-0"
                                    type="number"
                                ></v-text-field>

                            </v-card>
                        </v-list-item>
                    </v-list>
                </div>                                     
            </v-card>
        </v-row>

        <v-row
            class="mx-3 mb-0"
        >
           <v-col 
                cols="12"
                class=""
            >
                <v-btn
                    small
                    color="primary" 
                    text               
                    @click="searchStudent"
                    class="mr-4 caption"
                    outlined
                    block
                >
                    <v-icon
                        left                   
                    >mdi-account-search-outline</v-icon>
                    Search Student
                </v-btn>
           </v-col>         
        </v-row>
        <v-row
            class="mx-3 mb-0"
        >   
            <v-col 
                cols="12"
                class=""
            >
                <v-btn
                    small
                    color="primary"
                    text
                    @click="reportCard"
                    class="caption"
                    outlined
                    block
                >
                    <v-icon
                        left
                    >mdi-file-pdf-outline</v-icon>
                    Report Card
                </v-btn>
            </v-col>    
        </v-row>

        <v-row 
            justify="space-between"
            class="mt-3 mx-3 pr-3"
        >
            <v-btn 
                :class="btnClass"
                width="15"
                height="40"
                :dark="dark"
                :color="buttonColor"
                @click="previousRecord"
                :disabled="!pagination.prev_page"                
            >
                <div class="d-flex flex-column">                                         
                    <v-icon >mdi-step-backward</v-icon>
                    <span>PREV</span>
                </div>                                         
            </v-btn>
            
            <v-btn 
                class="text-caption pa-2"
                width="15"
                height="40"
                outlined
                disabled                
                text                
            >
                <span class="primary--text">{{pagination.current_page}} of {{ pagination.last_page}}</span>                                                        
            </v-btn>
            
            <v-btn 
                :class="btnClass"
                width="15"
                height="40"
                :dark="dark"
                :color="buttonColor"
                @click="nextRecord"
                :disabled="!pagination.next_page"
            >
                <div class="d-flex flex-column">                                         
                    <v-icon>mdi-step-forward</v-icon>
                    <span>NEXT</span>
                </div>                                         
            </v-btn>
        </v-row>
        <v-overlay
            absolute
            :value="overlay"
            color="grey"
            opacity="0.6"
        >
            <v-expand-transition>                            
                <v-card
                    max-width="600"
                    class="mx-auto pa-6"
                    transition="scroll-y-transition"
                    v-show="expand"
                    light
                >
                    <v-card-title
                        class="mb-6"
                    >
                        <v-text-field
                            v-model="search"
                            label="Search for Student Record"
                            append-icon="mdi-magnify"
                            class="mx-4"
                            hide-details
                            single-line
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="studentsRecords"
                        :search="search"
                        fixed-header
                        height="40vh"
                        :options="options"
                        dense
                    >
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                                text
                                color="primary"
                                @click="viewRecord(item)"
                            >
                                View Record
                            </v-btn>
                        </template>                        
                    </v-data-table>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            outlined
                            @click="cancelSearch"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>    
        </v-overlay>
        <v-dialog
            v-model="reportViewer"
            max-width="700px"
            @click:outside="closeReport"
        >
            <v-card
            height="90vh"
            style="position:relative;" 
            >                
                <iframe 
                    ref="pdf" 
                    style="width:100%; height:100%"                      
                    :src="src"
                ></iframe>              
                <v-overlay
                    absolute
                    :value="reportViewerOverlay"
                    color="white"
                    opacity="0.60"
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay> 
            </v-card>
        </v-dialog>  
    </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    mounted: function () {
        this.academicPeriod[0].value = this.academicYear;
        this.academicPeriod[1].value = this.term;        
    },
    computed: {
        ...mapGetters({
            academicYear: 'app/getAcademicYear',
            term: 'app/getTerm',
            primaryColor: 'app/getPrimaryColor',
            colorDarken: 'app/getColorDarken',
            formClassAssignment: 'termReports/getFormClassAssignment',            
            studentRecord: 'termReports/getStudentRecord',
            pagination: 'termReports/getPagination',
            currentPage: 'termReports/getCurrentPage',
            studentsRecords: 'termReports/getStudentsRecords',
        }),
        studentName(){
            return this.studentRecord?.first_name + ' ' + this.studentRecord?.last_name;
        },
        classId(){
            return this.studentRecord?.form_class_id;
        },
        recordSummary(){
            return [
                {title: 'Student\'s ID', model: 'student_id', select: false, disabled: true},               
                {title: 'Total Sessions', model: 'total_sessions', select: false, disabled: false},
                {title: 'Sessions Absent', model: 'sessions_absent', select: false, disabled: false},
                {title: 'Sessions Late', model: 'sessions_late', select: false, disabled: false},                
                // {title: 'Packages Collected', model: 'packages_collected', select: false, disabled: false},
                // {title: 'Packages Not Collected', model: 'packages_not_collected', select: false, disabled: false},
                
            ]
        },
        buttonColor(){
            return 'primary';
        },
        
    },
    watch: {
        studentRecord: {
            handler(val){                
                //this.setSavedToDb(true);
                if(!val) return;
                if(this.formClassAssignment.includes(this.studentRecord?.form_class_id)){
                    console.log('Assigned Form Teacher');
                    this.recordSummary.forEach(element => {
                        element.disabled = false;
                    })                    
                }
                else{
                    console.log('Not the form Teacher');
                    this.recordSummary.forEach(element => {
                        element.disabled = true;
                    })
                }
            }
        }
    },
    data: () => ({
        academicPeriod: [
            { title: 'Academic Year', value: '' },
            { title: 'Term', value: '' },
        ],
        btnClass: 'text-caption pa-2  white--text',
        dark: false,
        overlay: false,
        expand: false,
        search: '',
        headers: [
            { text: 'Student ID', align: 'center', value: 'student_id', width: '75' },
            { text: 'First Name', align: 'left', value: 'first_name', width: '100' },
            { text: 'Last Name', align: 'left', value: 'last_name', width: '100' },
            { text: '', align: 'center', value: 'actions', sortable: false, width: '150' },
        ],
        options: {
            itemsPerPage: -1,
        },
        reportViewer: false,
        reportViewerOverlay: false,
        src: '',      
    }),
    methods: {
        ...mapActions({
            getStudentTermDetails: 'termReports/getStudentTermDetails',
            getStudentTermMarks: 'termReports/getStudentTermMarks',
            postStudentDetails: 'termReports/postStudentTermDetails',        
        }),        
        ...mapMutations({
            setOverlay: 'termReports/setOverlay',
            setLoading: 'termReports/setLoading',       
            setPagination: 'termReports/setPagination',
            setStudentRecord: 'termReports/setStudentRecord',
            setStudentTermMarks: 'termReports/setStudentTermMarks',
            setCurrentPage: 'termReports/setCurrentPage',
            setSaveStatus: 'termReports/setSaveStatus',
            setSaving: 'termReports/setSaving',
            setSaved: 'termReports/setSaved',
            setSaveError: 'termReports/setError',
            setSavedToDb: 'termReports/setSavedToDb',
            setUpdating: 'termReports/setUpdating',            
        }),       
        async updateRecord(){
            console.log('updating...');
            this.setUpdating(true);
            this.setSavedToDb(false);
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);
            console.log(this.studentRecord);
            try{
                let response = await this.postStudentDetails();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
                this.setUpdating(false);
                console.log(response);
            } catch (error) {
                console.log(error.response);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                //this.setErrorResponse(error);
            }
            
        },
        previousRecord(){
            this.recordNavigation(this.pagination.prev_page);
            console.log('previous');
        },
        nextRecord(){
            this.recordNavigation(this.pagination.next_page);
            console.log('next');
        },
        async recordNavigation(pagination){
            console.log(pagination);
            this.setOverlay(true);
            this.setLoading(true);
            this.makePagination(pagination);   
            this.setStudentRecord(this.studentsRecords[--pagination]) 
            
            try {                
                let response = await this.getStudentTermMarks();
                console.log(response);
                this.setStudentTermMarks(response.data); 
            } catch (error) {
                console.log(error)
            }
            
            this.setOverlay(false);
            this.setLoading(false);
                   
        },
        makePagination(data){
            let prev_page = data;
            let current_page = data;
            let next_page = (current_page === this.pagination.last_page) ? null : ++data;
            prev_page = (current_page === 1) ? null : --prev_page;
            
            let pagination = {
                current_page: current_page,
                last_page: this.pagination.last_page,
                next_page: next_page,
                prev_page: prev_page,
            };
            this.setPagination(pagination);
        },
        searchStudent(){
            console.log('searching...');
            this.overlay = true;
            setTimeout(() => {this.expand = true});
            //this.expand = true;
        },
        viewRecord(item){
            //console.log(item);
            this.expand = false
            setTimeout(() => {this.overlay = false}, 1000);
            let recordIndex = this.studentsRecords.indexOf(item)
            this.recordNavigation(++recordIndex);
            //console.log(this.studentsRecords.indexOf(item));
        },
        cancelSearch(){
            this.expand = false
            setTimeout(() => {this.overlay = false}, 1000);           
        },
        reportCard(){
            console.log(this.studentRecord);
            this.reportViewer = true;
            this.reportViewerOverlay = true;
            this.$nextTick( function () {
                //console.log(this.$refs.pdf);
                this.$refs.pdf.onload = () => {
                    console.log('report is loaded');
                    this.reportViewerOverlay = false;
                }
            })  
            let student_id = this.studentRecord?.student_id;
            let academic_term_id = this.studentRecord.academic_term_id;
            this.src = process.env.VUE_APP_API_URI + "/api/report-card?termId="+academic_term_id+"&formClass="+this.studentRecord.form_class_id+"&studentId="+student_id;
        },
        closeReport(){
            this.src = '';
            this.reportViewer = false;
        }    
    }
}
</script>

<style scoped>
    ::v-deep .v-input__slot{
        min-height: 0px !important;
        display: flex !important;
        align-items: center !important;
    }

    .v-btn__content{
        font-size: 0.8em;
    }

    ::v-deep .v-input__append-inner{
        margin-top: 0;
    }

    ::v-deep .v-select__selections{
        height: 20px;
        /*padding: 0 !important;*/
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    ::v-deep .v-text-field__slot input{
        text-align: center;
    }
</style>